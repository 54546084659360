#root {
  height: 100%;
}

.linkWhiteText {
  color: #fff;
}

.widthHeight100 {
  width: '100%';
  height: '100%';
}

.loginCard {
  background: #292f36;
  padding: 90px 65px 30px 65px;
  max-height: 600px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 5);
}

.ant-input:focus {
  border-color: #4ECDC4;
}

.ant-input:hover {
  border-color: #4ECDC4;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #4ECDC4;
}

.accept-company-owner-invite .ant-form-item-label>label {
  color: #fff;
}

.accept-company-owner-invite .ant-form-item {
  margin-bottom: 10px;
}

.loginCard .ant-result-title {
  color: #fff;
}

.loginCard .ant-result-subtitle {
  color: #fff;
}