.bsd-btn-teal {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #4ecdc4;
    border-color: #4ecdc4;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.bsd-btn-teal:hover,
.bsd-btn-teal:focus {
    color: #fff;
    background-color: #0FA095;
    border-color: #0FA095;
}

.bsd-btn-orange {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #fa8c16;
    border-color: #fa8c16;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.bsd-btn-orange:hover,
.bsd-btn-orange:focus {
    color: #292f36;
    background-color: #ffc069;
    border-color: #ffc069;
}


.bsd-btn-blue {
    font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    font-size: small;
    padding: 0px 25px;
    color: #fff;
    background-color: #3b77ba;
    border-color: #3b77ba;
    border-radius: 2px;
    line-height: normal;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.bsd-btn-blue:hover,
.bsd-btn-blue:focus {
    color: #fff;
    background-color: #0d4d9b;
    border-color: #0d4d9b;
}