/* @import './assets/fonts/BebasNeue-Regular.ttf'; */

@font-face {
  font-family: 'BebasNeue';
  src: local('BebasNeue'), url(./Janus/assets/fonts/BebasNeue-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: BebasNeue, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lightbluetext {
    color: #0d4d9b;
}
.pb16{
    padding-bottom:16px;
}