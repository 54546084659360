.bsd-text-teal {
    color: #4ECDC4;
}

.bsd-text-orange {
    color: #fa8c16;
}

.bsd-text-blue {
    color: #3b77ba;
}