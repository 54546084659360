@import '~antd/dist/antd.css';

/***********BSD Colors***********/
/*Text Colors*/
.darkBlueText {
    color: #0D4D9B;
}
.tealText {
    color: #4ECDC4;
}
.orangeText {
    color: #FA8C16;
}

/*Background Colors*/
.blackBG {
    background-color: #292F36;
}
.mediumBlueBG {
    background-color: #fff;
}
@font-face {
    font-family: 'BebasNeue';
    src: local('BebasNeue'), url(../Janus/assets/fonts/BebasNeue-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'), url(../Janus/assets/fonts/NotoSans-Regular.ttf) format('truetype');
}

.tealBorder {
    border: 2px solid #4ECDC4;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4ECDC4;
  font-family: BebasNeue, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: NotoSans, 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.greenButton {
    color: #18db2c;
}
.greenButton:active {
    color: #18db2c;
}

.redButton {
    color: #db1818;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-typography strong {
  font-weight: 700;
}

.ant-layout {
  background-color: white;
}

.ant-layout-footer {
  background-color: white;
}

.ant-descriptions-title,
.ant-descriptions-item-label,
.ant-descriptions-item-content {
  color: #0D4D9B;
}

.bsd-light-blue {
  color: #E6F7FF;
}

.bsd-dark-blue {
  color: #0D4D9B;
}

.bsd-blue {
  color: #3B77BA;
}

.bsd-teal {
  color: #4ECDC4;
}

.bsd-off-white {
  color: #DFDFDF;
}

.bsd-light-blueBG {
  background-color: #E6F7FF;
}

.bsd-dark-blueBG {
  background-color: #0D4D9B;
}

.bsd-blueBG {
  background-color: #3B77BA;
}

.bsd-whiteBG {
    background-color: #fff;
}

.bsd-tealBG {
  background-color: #4ECDC4;
}

.bsd-off-whiteBG {
  background-color: #DFDFDF;
}

.ant-tabs-nav .ant-tabs-tab-active {
  background-color: #3B77BA;
  color: white;
  font-weight: 700; 
  padding-left: 10px;
  padding-right: 10px;
 
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color: #3B77BA;
    color: white;
}
.ant-tabs-ink-bar {
    background-color: #292f36;
}

.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  width: 4px;
}

.ant-tabs .ant-tabs-right-content {
  border-right: 2px solid #DFDFDF
}

ul.ant-list-item-action {
  display: flex;  
}

.ant-legacy-form-item-control-wrapper {
  width: 100%;
}

.StrippedButton {
    font-size: 100%;
    font-family: inherit;
    border: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: auto;
    cursor: pointer;
}